<template>
  <div class="content" v-loading="loading">
    <el-row style="padding: 15px 0;height:75px;line-height: 45px;">
      <el-col :span="17">
        <h2>{{teamInfo.name || '--'}}</h2>
      </el-col>
      <el-col :span="6" style="text-align: right;" :offset="1">
        <span>成员：{{teamInfo.group_num || 0}}</span>
        &nbsp; &nbsp; &nbsp;
        <span>创建时间：{{teamInfo.create_time_f || '--'}}</span>
      </el-col>
    </el-row>
    <div class="box">
      <el-route-tabs header-class="el-tabs-route" v-model="activeName" :body-style="{padding:0}" @tab-click="$handleRoute(activeName)" style="background-color: transparent;box-shadow:none;border:none;" :tab-list="tabList">
        <div slot="label" slot-scope="{label,pathname}" style="margin-top: 10px;line-height: 30px;">
          <el-badge :value="countData[pathname]" :max="99" :is-dot="countData['is-dot'] !== false" v-if="(countData[pathname] || 0) > 0">
            {{label}}
          </el-badge>
          <template v-else>
            {{label}}
          </template>
        </div>
        <keep-alive>
          <router-view v-bind="{teamUser,refreshMethod:()=>{getDetail(true)}}"></router-view>
        </keep-alive>
      </el-route-tabs>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    team_id:{
      default: () => 0,
    }
  },
  data() {
    return {
      activeName: '',
      tabList:[],
      loading:false,
      teamInfo:{},
      teamUser:{},
      countData:{notice:2},
    };
  },
  methods: {
    routeUpdate(){
      this.tabList = this.$router.getRoutes()
          .filter(d=>(d.parent || {}).name === this.currentRouteName && (d.meta || {}).hidden !== true).map(d=>{
        d.label = (d.meta || {}).title || d.name;
        d.pathname = d.name.substr(4,1).toLowerCase()+d.name.substr(5);
        return d;
      });
      this.getDetail(this.team_id);
    },
    routeChange({routerViewDepth}){
      let activeName =  (this.$route.matched[routerViewDepth + 1] || this.$route).name;
      this.getCount();
      var $currentTab = this.tabList.filter(d=>d.name===activeName)[0] || null
      if(!$currentTab){
        activeName = (this.tabList.filter(d=>activeName.indexOf(d.name) === 0)[0] || {} ).name || ''
      }
      this.activeName = activeName;
    },
    getCount(){
      this.$http.post('/xapi/user.group/getCount' ,
          {team_id : this.team_id,fields:this.tabList.map(d=>{
        return d.pathname;
        })}
          ,{message:false}
      ).then(({data})=>{
        this.countData = data || {}
      }).catch()
    },
    getDetail(refresh){
      if(this.team_id > 0 && (refresh === true || parseInt(this.team_id) !== parseInt(this.teamInfo.id || 0))) {
        this.loading = true;
        this.$http.post('/api/v1_0_0.user_group/detail' , {id : this.team_id})
        .then((data)=>{
          if(data.data.info){
            let teamInfo = data.data.info || {}
            if(this.userInfo.id > 0 && !(teamInfo.isManager || teamInfo.isManager === false || teamInfo.isManager === 0)){
              teamInfo.isManager = this.userInfo.id+'' === teamInfo.user_id + '';
            }
            this.teamInfo = {...teamInfo}
            this.teamUser = {...data.data.user}

          }else{
            this.$handleMessage(data.msg , 'error',()=>{ this.$routerGo(-1) });
          }
        }).catch().finally(()=>{
          this.loading = false;
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep {
  .el-tabs{
    .el-tabs__nav-wrap::after{
      display: none;
    }
  }
  // .el-tabs-route.el-tabs  .el-tabs__header{
  //   // padding: 0 60px;
  // }
  .box{
    .el-card__header{
      font-weight: 700;
    }
    .el-row{
      .el-col .el-card__header{
        border-bottom:0;
      }
      .el-col .el-card__header + .el-card__body{
        padding-top:0;
      }
    }
  }
}
</style>